import React from "react";
import { useParams } from "react-router-dom";
import { ExitIcon, TwitterIcon } from "@100mslive/react-icons";
import { Button, Flex, Box, Text, textEllipsis } from "@100mslive/react-ui";
import { ToastManager } from "./Toast/ToastManager";
import { Header } from "./Header";
import { useNavigation } from "./hooks/useNavigation";
import {
  UserPreferencesKeys,
  useUserPreferences,
  defaultPreviewPreference,
} from "./hooks/useUserPreferences";
import { getRoutePrefix } from "../common/utils";
import { userPeerIDState } from "./Huddle/HuddleState";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { fbFunctions } from "./hooks/useFirebase";
import { useState } from "react";

const PostLeave = () => {
  const navigate = useNavigation();
  const [interactedParticipantCount, setInteractedParticipantCount] = useState(0);
  const [longestConvo, setLongestConvo] = useState(0);
  const [totalEventTime, setTotalEventTime] = useState(0);
  const [userPeerID, setUserPeerID] = useRecoilState(userPeerIDState);
  // const userPeerID = "27c89122-5675-489e-9662-06bd3d8d9cc3"

  const { roomId, role } = useParams();
  const [previewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );

  useEffect(() => {

    let usersAndTime = {};
    let allEvents = null;
    let allPeers = [];
    //let participantsInteractedWith = 0;

    if (!userPeerID) return;
    let getIndividualStats = fbFunctions.httpsCallable('getIndividualStats');
    getIndividualStats({ peerID: userPeerID, roomName: roomId })
      .then((result) => {
        // console.log(result.data);
        allEvents = result.data;
        allPeers = getAllPeers(allEvents);
        console.log(allPeers);
        setTotalEventTime(getTotalEventTime(allEvents));

        let interactions = 0;
        allPeers.forEach(peerID => {
          if (peerID == userPeerID) return;
          let seconds = getTimeSpentWithPeer(allEvents, peerID);
          // console.log(seconds);
          console.log(seconds / 1000 + " seconds with " + peerID);
          if ((seconds / 1000) > 5) interactions++;

          if (longestConvo < (seconds / 1000)) setLongestConvo((seconds / 1000));
        });
        setInteractedParticipantCount(interactions);

      });
    // console.log(userPeerID);
  }, [])

  function getTotalEventTime(allEvents) {
    let startTime = null
    for (const [key, value] of Object.entries(allEvents)) {
      if (!startTime) startTime = key;
    }

    return (new Date().getTime() - Number(startTime)) / 1000;
  }

  function getTimeSpentWithPeer(allEvents, peerID) {
    let totalTime = 0;
    let startTime = null;
    for (const [key, value] of Object.entries(allEvents)) {
      // console.log(key);
      // console.log(!startTime);
      // console.log(!value.isInGatheringSpace);
      // console.log(value.peers.includes(peerID));
      if (startTime && !value.peers.includes(peerID)) {
        // console.log("Found out");
        let timeSpent = Number(key) - startTime;
        totalTime += timeSpent;
        startTime = null;
      } else if (!startTime && !value.isInGatheringSpace && value.peers.includes(peerID)) {
        // console.log("Found inclusion");
        startTime = Number(key);
      }
    }
    return totalTime;
  }

  function getAllPeers(allEvents) {
    let allPeers = [];
    for (const [key, value] of Object.entries(allEvents)) {
      // console.log(`${key}: ${value}`);
      allPeers = [...allPeers, ...value.peers]

    }
    return arrayUnique(allPeers)
  }

  function arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }
    return a;
  }

  const divStyles = {
    boxShadow: '1px 2px 9px #F4AAB9',
    marginTop: '1em',
  };

  return (
    <Flex direction="column" css={{ size: "100%" }}>
      <Box css={{ h: "$18", "@md": { h: "$17" } }} data-testid="header">
        <Header isPreview />
      </Box>
      <Flex
        // justify="center"
        direction="column"
        align="center"
        css={{ bg: "$mainBg", flex: "1 1 0", position: "relative" }}
      >
        <Text variant="h2" css={{ fontWeight: "$semiBold" }}>
          👋
        </Text>
        <Text
          variant="h4"
          css={{ color: "$textHighEmp", fontWeight: "$semiBold", mt: "$12" }}
        >
          You left the event
        </Text>
        {/* <Text
          variant="h5"
          css={{ color: "$textHighEmp", fontWeight: "$semiBold", mt: "$12" }}
        >
          🐦 You left the {getRoutePrefix() ? "stream" : "room"}
        </Text> */}
        {(totalEventTime / 60).toFixed(0) > 0 && <Text
          variant="body"
          css={{
            color: "$textMedEmp",
            mt: "$8",
            fontWeight: "$regular",
            textAlign: "center",
            fontSize: "20px"
          }}
        >
          🕦 You spent <b css={{ fontWeight: "$semiBold" }}>{(totalEventTime / 60).toFixed(0)} minutes </b> in the event.
        </Text>}
        {interactedParticipantCount>0 && <Text
          variant="body"
          css={{
            color: "$textMedEmp",
            mt: "$8",
            fontWeight: "$regular",
            textAlign: "center",
            fontSize: "20px"
          }}
        >
          🐦 You met <b css={{ fontWeight: "$semiBold" }}>{interactedParticipantCount} </b> other participants during the event.
        </Text>}
        {(longestConvo / 60).toFixed(0) > 0 && <Text
          variant="body"
          css={{
            color: "$textMedEmp",
            mt: "$8",
            fontWeight: "$regular",
            textAlign: "center",
            fontSize: "20px"
          }}
        >
          🍻 The longest impromptu networking session was <b css={{ fontWeight: "$semiBold" }}>{(longestConvo / 60).toFixed(0)} minutes </b>.
        </Text>}

        {roomId == "indie_worldwide" && <>
        <Text
          variant="body"
          css={{
            color: "$textMedEmp",
            mt: "$8",
            pt: "10px",
            fontWeight: "$regular",
            textAlign: "center",
            fontSize: "20px"
          }}
        >
          Tweet and Show off your skills on social media
        </Text>
         
        <Button
            onClick={() => {
              console.log("click");
              let encodedURL = "Had%20a%20blast%20at%20%40indie_worldwide%20event%20today%20on%20%40Huddl_space%F0%9F%94%A5%0A%0A%F0%9F%95%A6%20Spent%20"+(totalEventTime / 60).toFixed(0)+"%20minutes%20in%20the%20event.%0A%F0%9F%90%A6%20Met%20"+interactedParticipantCount+"%20other%20participants%20during%20the%20event.%0A%F0%9F%8D%BB%20My%20longest%20impromptu%20networking%20session%20was%20"+(longestConvo / 60).toFixed(0)+"%20minutes%20long."
              window.open("https://twitter.com/intent/tweet?text=" + encodedURL);
            }}
            data-testid="join_again_btn"
          >
            <TwitterIcon />
            <Text css={{ ml: "$3", fontWeight: "$semiBold", color: "inherit" }}>
              Generate Tweet
            </Text>
          </Button>
        {/* <TwitterIcon
            onClick={(e) => {
              console.log("click");
              let encodedURL = "Had%20a%20blast%20at%20%40indie_worldwide%20event%20today%20on%20%40Huddl_space%F0%9F%94%A5%0A%0A%F0%9F%95%A6%20Spent%20"+(totalEventTime / 60).toFixed(0)+"%20minutes%20in%20the%20event.%0A%F0%9F%90%A6%20Met%20"+interactedParticipantCount+"%20other%20participants%20during%20the%20event.%0A%F0%9F%8D%BB%20My%20longest%20impromptu%20networking%20session%20was%20"+(longestConvo / 60).toFixed(0)+"%20minutes%20long."
              window.open("https://twitter.com/intent/tweet?text=" + encodedURL);
            }}
          >
          </TwitterIcon> */}

          <div>
            <div style={divStyles}>
              <img src="./jdoeTweet.png" alt="Logo" width="400px"
                
              />
            </div>
          </div>
          
          

        </>}

        
        <Text
          variant="body1"
          css={{
            color: "$textMedEmp",
            mt: "$8",
            fontWeight: "$regular",
            textAlign: "center",
          }}
        >
          Have a nice day
          {previewPreference.name && (
            <Box as="span" css={{ ...textEllipsis(100) }}>
              , {previewPreference.name}
            </Box>
          )}
          !
        </Text>
        <Flex css={{ mt: "$14", gap: "$10", alignItems: "center" }}>
          <Text
            variant="body1"
            css={{ color: "$textMedEmp", fontWeight: "$regular" }}
          >
            Left by mistake?
          </Text>
          <Button
            onClick={() => {
              let previewUrl = "/preview/" + roomId;
              if (role) previewUrl += "/" + role;
              navigate(previewUrl);
              ToastManager.clearAllToast();
            }}
            data-testid="join_again_btn"
          >
            <ExitIcon />
            <Text css={{ ml: "$3", fontWeight: "$semiBold", color: "inherit" }}>
              Rejoin
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PostLeave;
