import { selectLocalPeerID, useHMSStore } from "@100mslive/react-sdk";
import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { fbFunctions } from "./hooks/useFirebase";


export function Stats() {
    const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
    const localPeerID = useHMSStore(selectLocalPeerID);

    useEffect(() => {
        let getIndividualStats = fbFunctions.httpsCallable('getIndividualStats');
        getIndividualStats({ peerID: localPeerID, roomName:urlRoomId })
        .then((result) => {
            console.log(result);
        });
    }, []);

    return (<h1></h1>)
}