import React, { useState } from "react";
import { Input, Button, styled } from "@100mslive/react-ui";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({ name, onChange, onJoin, enableJoin, passcode }) => {
  const formSubmit = e => {
    e.preventDefault();
  };
  const [passcodeEntered, setPasscodeEntered] = useState(null);

  return (
    <Form onSubmit={formSubmit}>
      <Input
        required
        id="name"
        css={{ w: "100%" }}
        value={name}
        onChange={e => onChange(e.target.value)}
        placeholder="Enter your name"
        autoFocus
        autoComplete="name"
      />
      {passcode && <Input
        required
        id="name"
        css={{ w: "100%" }}
        // value={name}
        onChange={e => setPasscodeEntered(e.target.value)}
        placeholder="Enter Passcode"
        autoFocus
        autoComplete="passcode"
      />}
      <Button type="submit" disabled={!name || !enableJoin || (passcode && passcode!=passcodeEntered)} onClick={onJoin}>
        {isStreamingKit() ? "Join Studio" : "Join Room"}
      </Button>
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});

export default PreviewName;
