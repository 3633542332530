import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";

// import setupLogRocketReact from "logrocket-react";
// import { Provider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'

// const rollbarConfig = {
//   accessToken: "6ebacbc381c5466eb0ae99567fd0406e",
//   environment: "testenv",
// };

if (process.env.REACT_APP_LOGROCKET_ID) {
  // LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
  // setupLogRocketReact(LogRocket);
}

Sentry.init({
  dsn: "https://8644bd38badf47bca3bf49a01d567553@o4505066818502656.ingest.sentry.io/4505066819878912",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Provider config={rollbarConfig}>
      <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary>
    </Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
