import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

// TODO: Use a configuration object
firebase.initializeApp({
  apiKey: "AIzaSyDYIT_OPEr10ABh9lGaHNXXlJSnI4i7RV0",
  authDomain: "huddle-37bd0.firebaseapp.com",
  projectId: "huddle-37bd0",
  storageBucket: "huddle-37bd0.appspot.com",
  messagingSenderId: "845763118142",
  appId: "1:845763118142:web:b679e8b37cd79f2cc33d6f",
  measurementId: "G-4MGSHH51Q0",
});

const db = firebase.firestore();
const fbFunctions = firebase.functions();
const auth = firebase.auth;

// eslint-disable-next-line no-restricted-globals
console.log(
  "process.env.REACT_APP_ENABLE_EMULATORS",
  process.env.REACT_APP_ENABLE_EMULATORS
);
if (process.env.REACT_APP_ENABLE_EMULATORS === "true") {
  db.useEmulator("localhost", 8080);
  fbFunctions.useEmulator("localhost", 5001);

  auth().useEmulator("http://localhost:9099/", { disableWarnings: true });
}

export default firebase;
export { db, auth, fbFunctions };
