import { atom } from "recoil";

export const userPeerIDState = atom({
  key: "userPeerID", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const userProximityUsersState = atom({
  key: "proximityUsers", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const userMapPlayerLocationsState = atom({
  key: "playerLocations", // unique ID (with respect to other atoms/selectors)
  default: new Map(), // default value (aka initial value)
});

export const userMapAllPlayers = atom({
  key: "allPlayers", // unique ID (with respect to other atoms/selectors)
  default: new Map(), // default value (aka initial value)
});

export const userTileExpandState = atom({
  key: "isTileExpanded", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const isBotModeActiveState = atom({
  key: "isBotModeActive", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const isControlsDisabledState = atom({
  key: "isControlsDisabled", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const roomLayoutState = atom({
  key: "roomLayout", // unique ID (with respect to other atoms/selectors)
  default: { layout: "", layoutName: "" }, // default value (aka initial value)
});

export const AIWindowState = atom({
  key: "AIWindow", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const roomPropertiesState = atom({
  key: "roomProperties", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const isUserIdleState = atom({
  key: "isUserIdle", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
